<template>
  <router-view></router-view>
</template>

<script>
import router from '../../router'
import { mapGetters } from 'vuex'

export default {
  name: 'Admin',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  mounted() {
    if (this.user.data && !this.user.data.admin) {
      router.push('/')
    }
    else {
      this.user.show_admin = true
    }
  },
}
</script>
